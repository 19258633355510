import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useCompetitionData } from "../dashboard/useCompetitionData";
import { Competition } from "./types";

const SelectCompetition = ({
    competitionId,
    updateCompetition,
    updateTitle,
}: {
    competitionId: string;
    updateCompetition: any;
    updateTitle: any;
}) => {
    const { retreiveMyCompetitions } = useCompetitionData();
    const [competitionData, updateCompetitionData] = useState<[Competition]>();
    useEffect(() => {
        const fetchData = async () => {
            const data: [Competition] = await retreiveMyCompetitions(false);
            updateCompetitionData(data);
            if (!competitionId && data[0]) {
                updateCompetition(data[0].competitionId);
                updateTitle(data[0].rk);
            }
        };
        fetchData();
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClick = (tournament: Competition) => {
        updateCompetition(tournament.competitionId);
        updateTitle(tournament.rk);
        setShow(false);
    };

    return (
        <>
            <Button variant="light" onClick={handleShow}>
                <i className="bi bi-list" /> Competitions
            </Button>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Select Competition</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container>
                        {competitionData
              && competitionData.length > 0
              && competitionData.map((tournament: Competition) => (
                  <Row key={tournament.competitionId}>
                      <Button
                          variant="light"
                          onClick={() => {
                              handleClick(tournament);
                          }}
                      >
                          {tournament.rk}
                      </Button>
                  </Row>
              ))}
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export { SelectCompetition };
