import axios from "axios";
import { useConfigContext } from "../context/config-context";
import { useAuthContext } from "../context/auth-context";
import { mockData, mockCompetitions } from "./mockDataProvider";
import { Fixture, Prediction } from "./types";

const useCompetitionData = () => {
    const { apiBaseUrl } = useConfigContext();
    const {
        tokenStore: { idToken },
    } = useAuthContext();
    // const competitionId = "6cdae0be-8cbb-4352-9f30-7e9d2b311302"; // 2024 Mens
    // const competitionId = "4c1f294f-fb88-491f-b34e-03c1a1736e2b"; // 2024 Womens
    // const competitionId = "cd096f0e-fdfc-4e89-9462-de0f2299ed12"; // 2025 Mens
    const username = "gavinepotter@gmail.com";

    axios.defaults.headers.common.Authorization = `${idToken}`;

    const retreiveMyCompetitions = async (useMock: boolean = false) => {
        try {
            const { data } = useMock ? mockCompetitions : await axios.get(`${apiBaseUrl}/competitions/${username}`);
            return data;
        } catch (error) {
            console.error(error);
            return {};
        }
    };

    const retrieveCompetitionData = async (dynamicCompetitionId: string, useMock: boolean = false) => {
        try {
            const { data } = useMock
                ? mockData
                : await axios.get(`${apiBaseUrl}/competition/${dynamicCompetitionId}/dashboard`);
            return data;
        } catch (error) {
            console.error(error);
            return {};
        }
    };

    const submitPrediction = async (dynamicCompetitionId: string, prediction: Prediction, useMock: boolean = false) => {
        try {
            if (useMock) {
                console.log("mocking sumbit call");
                console.log(prediction.fixtureId);
                console.log(prediction.homePrediction);
                console.log(prediction.awayPrediction);
            } else {
                await axios.post(`${apiBaseUrl}/competition/${dynamicCompetitionId}/predictions`, prediction);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const submitName = async (dynamicCompetitionId: string, name: string) => {
        await axios.post(`${apiBaseUrl}/competition/${dynamicCompetitionId}/players`, { name });
    };

    const submitScores = async (dynamicCompetitionId: string, fixture: Fixture, useMock: boolean = false) => {
        try {
            if (useMock) {
                console.log("mocking sumbit call");
                console.log(fixture);
            } else {
                await axios.post(`${apiBaseUrl}/competition/${dynamicCompetitionId}/fixtures`, fixture);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return {
        retreiveMyCompetitions,
        retrieveCompetitionData,
        submitPrediction,
        submitName,
        submitScores,
    };
};

export { useCompetitionData };
