/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LogOut } from "./auth/LogOut";
import { Authentication } from "./auth/Authentication";
import { useAuthContext } from "./context/auth-context";
import { UserAuthenticated } from "./context/types";
import { useAuthentication } from "./auth/useAuthentication";
import { Dashboard } from "./dashboard/Dashboard";
import logo from "./img/logo.png";

const App = ({ retrieveCompetitionData, submitName }: { retrieveCompetitionData: any; submitName: any }) => {
    const [title, updateTitle] = useState<string>("");
    const { authStatus } = useAuthContext();
    const { refreshLogin } = useAuthentication();

    useEffect(() => {
        if (authStatus === UserAuthenticated.UNKNOWN) {
            refreshLogin();
        }
    });

    return (
        <>
            <header className="text-center pb-5">
                {authStatus === UserAuthenticated.YES ? (
                    <div className="content">
                        <img src={logo} alt="Guinness Six Nations Logo" />
                        <h1 className="display-3">{title}</h1>
                    </div>
                ) : (
                    <h1 className="display-3">Super Duper Competition Tournament App!</h1>
                )}
            </header>

            {authStatus === UserAuthenticated.UNKNOWN ? (
                "Authenticating ..."
            ) : authStatus === UserAuthenticated.YES ? (
                <>
                    <Dashboard
                        updateTitle={updateTitle}
                        retrieveCompetitionData={retrieveCompetitionData}
                        submitName={submitName}
                    />
                </>
            ) : (
                <Container>
                    <Row>
                        <Col className="col-lg-6 mt-5 offset-lg-3">
                            <Authentication />
                        </Col>
                    </Row>
                </Container>
            )}

            <footer className="container-fluid text-center pb-5 pt-5 d-grid d-md-block">
                {authStatus === UserAuthenticated.YES && <LogOut />}
            </footer>
        </>
    );
};

export { App };
