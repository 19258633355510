/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
import React, { useState } from "react";
import { CompetitionData, Fixture, Player } from "./types";
import { useCompetitionData } from "./useCompetitionData";

const emptyFixture: Fixture = {
    id: -1,
    homeTeam: "",
    awayTeam: "",
    homeEmblemUri: "",
    homeEmblemUriText: "",
    awayEmblemUri: "",
    awayEmblemUriText: "",
    homeEmblemUriNegative: "",
    homeEmblemUriNegativeText: "",
    awayEmblemUriNegative: "",
    awayEmblemUriNegativeText: "",
    startTime: "",
    channel: "",
    homeScore: "",
    awayScore: "",
    active: false,
    fullTime: false,
};

const Fixtures = ({
    competitionData,
    fixtures,
    players,
    email,
}: {
    competitionData: CompetitionData;
    fixtures: Fixture[];
    players: Player[];
    email: string;
}) => {
    const [newScores, updateNewScores] = useState<Fixture>(emptyFixture);

    const { submitScores } = useCompetitionData();

    const handleFormSubmit = () => {
        submitScores(competitionData.pk, newScores);
        updateNewScores(emptyFixture);
        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => location.reload(), 500);
    };

    const handleUpdateClick = (fixture: Fixture) => {
        updateNewScores(fixture);
    };

    return (
        <div className="container text-center p-md-5 mt-5 fixtures-panel">
            <h2 className="pb-3 pt-3">Fixtures</h2>
            <div className="fixtures d-md-flex">
                {(fixtures as Fixture[]).map(fixture => (
                    <div
                        key={`${fixture.homeTeam}-${fixture.awayTeam}`}
                        className={`col fixture ${
                            fixture.active && "active"
                        } m-0 mb-5 pt-3 pb-3 rounded-3 row flex-column justify-content-between`}
                    >
                        <div className="row">
                            <div className="col-4">
                                <img
                                    alt={fixture.homeEmblemUriNegativeText}
                                    src={fixture.homeEmblemUriNegative}
                                    className="fixtureLogo"
                                />
                            </div>
                            <div className="col-4">
                                <p className="display-5">
                                    {fixture.homeScore} : {fixture.awayScore}
                                </p>
                                <p>{fixture.fullTime ? "FT" : `${fixture.startTime} | ${fixture.channel}`}</p>
                            </div>
                            <div className="col-4">
                                <img
                                    alt={fixture.awayEmblemUriNegativeText}
                                    src={fixture.awayEmblemUriNegative}
                                    className="fixtureLogo"
                                />
                            </div>
                        </div>
                        <div className="row accordion  accordion-flush mt-3" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    {(fixture.active || fixture.fullTime) && (
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${fixture.homeTeam}-${fixture.awayTeam}`}
                                            aria-expanded="false"
                                            aria-controls={`collapse-${fixture.homeTeam}-${fixture.awayTeam}`}
                                        >
                                            View Predictions
                                        </button>
                                    )}
                                </h2>
                                <div
                                    id={`collapse-${fixture.homeTeam}-${fixture.awayTeam}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th> </th>
                                                    <th>{fixture.homeTeam}</th>
                                                    <th> </th>
                                                    <th>{fixture.awayTeam}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(players as Player[])
                                                    .filter(p => p.name !== "TBA")
                                                    .map(player => (
                                                        <tr key={player.email}>
                                                            <td>{player.name}</td>
                                                            <td>
                                                                {player.predictions.find(p => p.fixtureId === fixture.id)
                                  && player.predictions.find(p => p.fixtureId === fixture.id)?.homePrediction}
                                                            </td>
                                                            <td>:</td>
                                                            <td>
                                                                {player.predictions.find(p => p.fixtureId === fixture.id)
                                  && player.predictions.find(p => p.fixtureId === fixture.id)?.awayPrediction}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 mb-2 d-grid ms-auto flex-grow-1">
                            {email === "gavinepotter@gmail.com" && competitionData.isActive && (
                                <button
                                    type="button"
                                    className="btn btn-success btn-lg shadow-sm score-button"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#score-${fixture.homeTeam}-${fixture.awayTeam}`}
                                    onClick={() => handleUpdateClick(fixture)}
                                >
                                    Update Live Score
                                </button>
                            )}

                            <div
                                className="modal fade"
                                id={`score-${fixture.homeTeam}-${fixture.awayTeam}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel">
                                                Add Prediction
                                            </h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            <div className="mb-3 row">
                                                <div className="col-6">
                                                    <div className="input-group">
                                                        <span className="input-group-text text-center">
                                                            {" "}
                                                            <img alt={fixture.homeEmblemUriText} src={fixture.homeEmblemUri} className="formLogo" />
                                                        </span>
                                                        <textarea
                                                            className="form-control"
                                                            onChange={({ target: { value } }) => updateNewScores({
                                                                ...newScores,
                                                                id: fixture.id,
                                                                homeScore: Number.isNaN(Number.parseInt(value, 10)) ? "" : Number.parseInt(value, 10),
                                                            })}
                                                            value={newScores.homeScore}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="input-group">
                                                        <textarea
                                                            className="form-control"
                                                            onChange={({ target: { value } }) => updateNewScores({
                                                                ...newScores,
                                                                id: fixture.id,
                                                                awayScore: value ? Number.parseInt(value, 10) : 0,
                                                            })}
                                                            value={newScores.awayScore}
                                                        />
                                                        <span className="input-group-text text-center">
                                                            {" "}
                                                            <img alt={fixture.awayEmblemUriText} src={fixture.awayEmblemUri} className="formLogo" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <label className="form-check-label" htmlFor="activeCheck" style={{ color: "black" }}>
                                                            Active
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="activeCheck"
                                                            checked={newScores.active}
                                                            onChange={({ target: { checked } }) => updateNewScores({
                                                                ...newScores,
                                                                id: fixture.id,
                                                                active: checked,
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <label className="form-check-label" htmlFor="completedCheck" style={{ color: "black" }}>
                                                            Completed
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="completedCheck"
                                                            checked={newScores.fullTime}
                                                            onChange={({ target: { checked } }) => updateNewScores({
                                                                ...newScores,
                                                                id: fixture.id,
                                                                fullTime: checked,
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                                Cancel
                                            </button>
                                            <button type="button" className="btn btn-primary" onClick={handleFormSubmit}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { Fixtures };
