import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { App } from "./App";
import { AuthProvider } from "./context/auth-context";
import { ConfigProvider } from "./context/config-context";
import { useCompetitionData } from "./dashboard/useCompetitionData";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const Root = () => {
    const { retrieveCompetitionData, submitName } = useCompetitionData();
    return (
        <React.StrictMode>
            <ConfigProvider>
                <AuthProvider>
                    <App retrieveCompetitionData={retrieveCompetitionData} submitName={submitName} />
                </AuthProvider>
            </ConfigProvider>
        </React.StrictMode>
    );
};

root.render(<Root />);
