import React, { useState } from "react";
import { CompetitionData, Fixture, Prediction } from "./types";
import { useCompetitionData } from "./useCompetitionData";

const Predictions = ({
    competitionData,
    predictions,
    fixtures,
}: {
    competitionData: CompetitionData;
    predictions: Prediction[];
    fixtures: Fixture[];
}) => {
    const getFixture = (fixtureId: number) => fixtures.find(fixture => fixture.id === fixtureId) ?? {
        homeEmblemUri: "",
        awayEmblemUri: "",
        homeEmblemUriText: "",
        awayEmblemUriText: "",
        homeTeam: "",
        awayTeam: "",
        homeScore: "",
        awayScore: "",
    };

    const [newPrediction, updateNewPrediction] = useState<Prediction>({
        fixtureId: -1,
        homePrediction: "",
        awayPrediction: "",
    });

    const { submitPrediction } = useCompetitionData();

    const handleFormSubmit = () => {
        submitPrediction(competitionData.pk, newPrediction);
        updateNewPrediction({ fixtureId: -1, homePrediction: 0, awayPrediction: 0 });
        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => location.reload(), 500);
    };

    const handleUpdateClick = (prediction: Prediction) => {
        updateNewPrediction(prediction);
    };

    return (
        <div className="col-md-6 container text-center pt-5 p-4 p-md-5">
            <h2 className="pb-3">My Predictions</h2>
            <div className="my-predictions pb-3">
                {(fixtures as Fixture[]).map((fixture: Fixture) => (
                    <div key={fixture.id} className="row match m-0 p-3 rounded-3 mb-3 d-flex justify-content-between">
                        <div className="col-4 m-0 p-0">
                            <img
                                alt={getFixture(fixture.id).homeEmblemUriText}
                                src={getFixture(fixture.id).homeEmblemUri}
                                className="predictionLogo"
                            />
                        </div>
                        <div className="col-4 col-md-4 ">
                            {predictions.find(p => p.fixtureId === fixture.id) && (
                                <p className="display-5">
                                    {predictions.find(p => p.fixtureId === fixture.id)?.homePrediction} :{" "}
                                    {predictions.find(p => p.fixtureId === fixture.id)?.awayPrediction}
                                </p>
                            )}
                            {competitionData.isActive && !fixture.active && !fixture.fullTime && (
                                <button
                                    type="button"
                                    className="btn btn-success btn-lg shadow-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#prediction-${getFixture(fixture.id).homeTeam}-${getFixture(fixture.id).awayTeam}`}
                                    onClick={() => handleUpdateClick({
                                        fixtureId: fixture.id,
                                        homePrediction: predictions.find(p => p.fixtureId === fixture.id)?.homePrediction || "",
                                        awayPrediction: predictions.find(p => p.fixtureId === fixture.id)?.awayPrediction || "",
                                    })}
                                >
                                    {predictions.find(p => p.fixtureId === fixture.id) ? "amend" : "add"} prediction
                                </button>
                            )}
                        </div>
                        <div className="col-4 m-0 p-0">
                            <img
                                alt={getFixture(fixture.id).awayEmblemUriText}
                                src={getFixture(fixture.id).awayEmblemUri}
                                className="predictionLogo"
                            />
                        </div>
                        <div className="col-12  col-md-4 p-0 pt-4 d-grid d-md-block">
                            <div
                                className="modal fade"
                                id={`prediction-${getFixture(fixture.id).homeTeam}-${getFixture(fixture.id).awayTeam}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel">
                                                Add Prediction
                                            </h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            <div className="mb-3 row">
                                                <div className="col-6">
                                                    <div className="input-group">
                                                        <span className="input-group-text text-center">
                                                            {" "}
                                                            <img
                                                                className="formLogo"
                                                                alt={getFixture(fixture.id).homeEmblemUriText}
                                                                src={getFixture(fixture.id).homeEmblemUri}
                                                            />
                                                        </span>
                                                        <textarea
                                                            className="form-control"
                                                            aria-label="Scotland"
                                                            onChange={({ target: { value } }) => updateNewPrediction({
                                                                ...newPrediction,
                                                                homePrediction: Number.isNaN(Number.parseInt(value, 10))
                                                                    ? ""
                                                                    : Number.parseInt(value, 10),
                                                            })}
                                                            value={newPrediction.homePrediction}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="input-group">
                                                        <textarea
                                                            className="form-control"
                                                            aria-label="Wales"
                                                            onChange={({ target: { value } }) => updateNewPrediction({
                                                                ...newPrediction,
                                                                awayPrediction: Number.isNaN(Number.parseInt(value, 10))
                                                                    ? ""
                                                                    : Number.parseInt(value, 10),
                                                            })}
                                                            value={newPrediction.awayPrediction}
                                                        />
                                                        <span className="input-group-text text-center">
                                                            {" "}
                                                            <img
                                                                className="formLogo"
                                                                alt={getFixture(fixture.id).awayEmblemUriText}
                                                                src={getFixture(fixture.id).awayEmblemUri}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-dismiss="modal"
                                                onClick={handleFormSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { Predictions };
