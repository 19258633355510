/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
import React, { useEffect, useState } from "react";
import { CompetitionData } from "./types";
import { Leaderboard } from "./Leaderboard";
import { Predictions } from "./Predictions";
import { Fixtures } from "./Fixtures";
import { useAuthContext } from "../context/auth-context";
import { SelectCompetition } from "../nav/SelectCompetition";
import { useAuthentication } from "../auth/useAuthentication";

const Dashboard = ({
    updateTitle,
    retrieveCompetitionData,
    submitName,
}: {
    updateTitle: any;
    retrieveCompetitionData: any;
    submitName: any;
}) => {
    const [competitionData, updateCompetitionData] = useState<CompetitionData>();
    const [competition, updateCompetition] = useState<string>("");
    const [newName, updateNewName] = useState<string>("");
    const { refreshLogin } = useAuthentication();

    useEffect(() => {
        const fetchData = async () => {
            if (competition) {
                const data: CompetitionData = await retrieveCompetitionData(competition, false);
                console.log("DEBUG", data);
                updateCompetitionData(data);
                if (data.pk !== competition) updateCompetition(data.pk);
            }
        };
        fetchData();

        const interval = setInterval(() => {
            refreshLogin();
            fetchData();
        }, 5000);

        return () => clearInterval(interval);
    }, [competition]);

    const { email } = useAuthContext();
    const { players, fixtures } = competitionData ?? { players: [], fixtures: [] };
    const { predictions, name } = players
        ? players.find(player => player.email === email) ?? { predictions: [] }
        : { predictions: [], name: "" };

    const handleFormSubmit = () => {
        submitName(competition, newName);

        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => location.reload(), 500);
    };

    return (
        <main className="container-fluid">
            <SelectCompetition competitionId={competition} updateCompetition={updateCompetition} updateTitle={updateTitle} />
            {(!competitionData || !competitionData.owner) && <p>Loading ...</p>}
            {!name && competitionData && competitionData.owner && (
                <div className="row">
                    <div className="col-md-6 container text-center p-md-5 pt-5 pb-0 mb-5">
                        <h3>Looks like you haven&apos;t registered for this tournament yet!</h3>
                        <button
                            type="button"
                            className="btn btn-success btn-lg shadow-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#name-form"
                        >
                            Introduce yourself!
                        </button>
                        <div
                            className="modal fade"
                            id="name-form"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Hello there!
                                        </h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">
                                                Tell us your name:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Chris Paterson"
                                                value={newName}
                                                onChange={({ target: { value } }) => updateNewName(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-dismiss="modal"
                                            onClick={handleFormSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {competitionData && name && (
                <>
                    <div className="row">
                        <Leaderboard players={players} />
                        <Predictions competitionData={competitionData} predictions={predictions} fixtures={fixtures} />
                    </div>
                    <div className="row">
                        <Fixtures competitionData={competitionData} fixtures={fixtures} players={players} email={email} />
                    </div>
                </>
            )}
        </main>
    );
};

export { Dashboard };
